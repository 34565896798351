.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 40px;
}

.header {
  background-color: black;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: grey;
  margin-bottom: 20px;
}

button, .button {
  cursor: pointer;
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  color: white;
  background-color: black;
  font-style: normal;
  text-decoration: none;
}

button:disabled {
  cursor: not-allowed! important;
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  color: #ababab;
  background-color: #5c5c5c;
  font-style: normal;
}

a.disabled {
  pointer-events: none;
  cursor: not-allowed! important;
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  color: #ababab;
  background-color: #5c5c5c;
  font-style: normal;
}

.smaller-text {
  font-size: 0.8em;
}

.details-image {
  /*height: 350px;*/
  margin-top: 50px;
  border: 2px black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
